import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { FileTypes, ImageLoaderService } from '@lc/core';
import { InputField } from '../../inputs/input-field';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'lc-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends InputField implements OnInit {

  @ViewChild('fileInput')
  fileInput: ElementRef;

  // TODO - abstract this component by allowing a templateRef passed. This is not complete code but the start
  @Input()
  uploadTemplate: TemplateRef<any>;

  // TODO - remove the two inputs and replace with class names to identify components
  @Input()
  inputId: string; // the id of the upload input field - used for e2e

  @Input()
  btnId: string; // the id of the upload button - used for e2e

  @Input()
  label: string; // text to display as a component label

  @Input()
  imageUrl: string;  // image to display. If null, then photo image will display.

  @Input()
  instructions: string; // text to display for the component

  @Input()
  allowedExtensions: any[] = [];  // File validation constraints

  @Input()
  processing: boolean;  // determines if the component should accept files

  @Output()
  uploadResult = new EventEmitter<File[]>();

  @Input()
  photoStyle: null | 'round';

  allowExt = [];

  constructor(sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private imageLoaderService: ImageLoaderService) {
    super(sanitizer);
  }

  ngOnInit() {
    this.allowExt = this.allowedExtensions?.map(ext => '.' + ext);
  }

  // called for drag-n-drop
  async handleDroppedFiles(event: NgxFileDropEntry[]) {
    if (!this.processing) {
      this.uploadFiles(await this.getFilesFromUploadEvent(event));
    }
  }

  // called for file chooser input
  handleSelectedFiles(event) {
    this.uploadFiles(event.target.files);
  }

  async getFilesFromUploadEvent(event: NgxFileDropEntry[]) {
    const filePromises = [];
    event.forEach(droppedFile => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const filePromise = new Promise((resolve) => {
          fileEntry.file(resolve);
        });
        filePromises.push(filePromise);
      }
    });
    return Promise.all(filePromises);
  }

  uploadFiles(files: File[]) {
    if (this.allowedExtensions && this.allowedExtensions.length > 0) {
      const actualFiles = Array.from(files);
      const ext = actualFiles[0]?.name?.split('.');
      const fType = ext[ext.length-1]?.toLowerCase();
      if (!this.allowedExtensions.includes(fType)) {
        this.errorMsg();
        return;
      }
      this.validateBufferContent(files);
    } else {
      this.uploadResult.emit(files);
    }
  }

  public chooseFiles() {
    this.fileInput?.nativeElement?.click();
  }

  private validateBufferContent(files: File[]) {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const ext = this.imageLoaderService.getTypeForData(e.target.result);
      if (!ext || !this.allowedExtensions.includes(ext.suffix)) {
        this.errorMsg();
        return;
      }
      this.uploadResult.emit(files);
    }
    const blob = files[0].slice(0, 4);
    fileReader.readAsArrayBuffer(blob);
  }

  errorMsg() {
    const message = `Only PNG and JPG files are allowed`;
    this._snackBar.open(message, 'OK', {
      duration: 5000,
    });
  }
}
