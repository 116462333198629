import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { LoadLoggedInUser } from '../state-mgmt/user/user.actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadProfile } from '../state-mgmt/profile/profile.actions';
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { AddressInformation } from '../models/address-information.model';
import { AddressType } from '../models/address.type';
import { map } from 'rxjs/operators';
import { ProfileInfo } from '../models/profile-info.model';

@Injectable()
export class UserService {

  resource = 'user';

  //TODO Use store.select(SELECTEDPROFILE) to get the current user profile.
  //TODO from LC-416: replace currentUser with store.select(LOGGEDINUSER)

  private currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser = this.currentUserSubject.asObservable();

  private readonly preferencesCategory: string;
  private readonly CURRENT_USER = 'currentUser';
  private readonly IMPERSONATED_BY = 'impersonatedBy';
  private readonly IMPERSONATED_USER = 'impersonatedUser';

  constructor(private apiService: ApiService,
              private store: Store<any>) {
    this.loadFromLocalStorage();
    this.preferencesCategory = AppService.get('applicationKey');
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  public getUserId() {
    const user = this.getCurrentUser();
    return (user) ? user._id : null;
  }

  public getUserFirstName() {
    const user = this.getCurrentUser();
    if (!user)
      return '';

    return (user.profile && user.profile.preferredFirstName) ? user.profile.preferredFirstName.replace('.', '') : user.firstName.replace('.', '');
  }

  public getUser() {
    const user = this.getCurrentUser();
    if (!user)
      return '';

    return user;
  }

  public async onAuthenticated(user: User) {
    this.setCurrentUser(user);
    if(user && user._assumedUser) {
      setTimeout(async () => await this.assume(user._assumedUser), 200);
    }
  }

  public setCurrentUser(user: User, loadedFromStorage?: boolean) {
    this.currentUserSubject.next(user);
    this.store.dispatch(new LoadLoggedInUser(user));
    this.store.dispatch(new LoadProfile(user.profile));

    this.setupDataLayer();
    if (!loadedFromStorage) {
      this.saveToLocalStorage();
    }
  }

  /**
   * Sets the preference item under the given path, adding to the structure if necessary, and then saves
   * preferences to server. Path uses dots as separators.
   *
   * Example: this.is.the.path.to.item
   *
   * @param path Parent path to preference item. Can be null.
   * @param item Item to set
   * @param value Value to set
   */
  public setPreference(path:string, item:string, value:any):void {
    if (item && item.length) {
      path += "." + item;
    }
    const preferences = this.getPreferences();
    if (preferences) {
      UserService.findAndSetPreference(preferences, path, value);
      this.savePreferences(preferences);
    }
  }

  /**
   * Gets the value of a preference value.
   * Example: this.is.the.path.to.item
   *
   * @param path Path to item using dotted notation. Path includes the item name.
   *
   * Return item value (can be an object) or null if no item exists on the path.
   */
  public getPreferenceValue(path:string):any {
    const preferences = this.getPreferences();
    let val = preferences;
    path.split('.').forEach(p => {
      if (val) {
        val = val[p];
      }
    });
    return val;
  }

  /**
   * Utility method that sets the value of a nested object. Recursively traverses a json object to find the
   * portion referenced by the parameters. If the json does not have the component in the path, it is added.
   *
   * @param preferences Any json
   * @param remainder Remaining path component. Function will recurse if there is any remainder.
   * @param value Value to set when end of path is reached.
   * @param next Next component in path. Optional.
   */
  static findAndSetPreference(preferences:any, remainder:string, value:any, next?:string):void {
    if (!remainder || !remainder.length) {
      // We've traversed the entire path, so set the value
      preferences[next] = value;
    } else {

      // Next is optional, so the entire path may be represented by remainder for the initial call
      if (next && next.length) {
        // Descend into the next level. If there is nothing there, create it
        if (!preferences[next]) {
          preferences[next] = {}
        }
        preferences = preferences[next];
      }
      // Split the remainder to identify the next level of 'next' and 'remainder'
      const index = remainder.indexOf('.');
      if (index === -1) {
        next = remainder;
        remainder = null;
      } else {
        next = remainder.substr(0, index);
        remainder = remainder.substr(index + 1);
      }
      // Process the next level recursively
      UserService.findAndSetPreference(preferences, remainder, value, next);
    }
  }

  /**
   * Saves the users preferences to the server.
   * @param preferences
   */
  savePreferences(preferences: any):void {
    // Update local value
    if (this.preferencesCategory) {
      const u = this.currentUserSubject.value;
      if (!u.preferences) {u.preferences = [];}
      const index = u.preferences.findIndex(p => p.category === this.preferencesCategory);
      if (index >= 0) {
        u.preferences[index].preferences = preferences;
      } else {
        u.preferences.push({"category": this.preferencesCategory, "preferences":preferences});
      }

      // Send value to server
      this.apiService.put(this.resource + '/preferences/' + this.preferencesCategory, preferences)
        .subscribe(()=>{ /* Refactor me to promise */}, (error) => { throw new Error(error); });
    }
  }

  /**
   * Gets the preference object for the current application.
   */
  getPreferences():any {

    if (!this.preferencesCategory || !this.currentUserSubject.value || !this.currentUserSubject.value.preferences) {
      return null;
    }
    const category : any = this.currentUserSubject.value.preferences.find(p => {
      return p.category === this.preferencesCategory ;
    });
    return (category && category.preferences) ? category.preferences : {};
  }

  clearUser(userString = this.CURRENT_USER) {
    localStorage.removeItem(userString);

    if(userString === this.CURRENT_USER) {
      this.currentUserSubject.next(null);
    }
  }

  getUserFromStorage(storageKey: string) {
    const user = localStorage.getItem(storageKey);
    if(user != null) {
      return new User(JSON.parse(user));
    }
    return null;
  }

  setupDataLayer() {
    const user = this.getCurrentUser();
    if (!user || !user.profile) {
      return false;
    }
  }

  saveToLocalStorage(userToSave?: User) {
    const user = userToSave ? userToSave : this.getCurrentUser();
    localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
  }

  loadFromLocalStorage() {
    const currentUser = JSON.parse(localStorage.getItem(this.CURRENT_USER));

    // Ensure that a model change has not caused the user in localStorage to be incompatible
    if (currentUser && !this.isEmpty(currentUser)) {
      const user = new User().deserialize(currentUser);
      this.setCurrentUser(user, true);
    }
  }

  private isEmpty(value){
    return  value === undefined ||
            value === null ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (typeof value === "string" && value.trim().length === 0)
  }

  getAddress(type: AddressType): AddressInformation {
    const user = this.getCurrentUser();
    if (!user) { return null; }

    return user.getAddress(type);
  }

  onBehalfOfUsers$(params?: {search?: string}): Observable<ProfileInfo[]> {
    return this.apiService.get<ProfileInfo[]>(`${this.resource}/on-behalf-of-users`, params).pipe(
      map(users => users.map(user => new ProfileInfo(user)))
    );
  }

  getAllUsers$(params?: {search?: string}): Observable<ProfileInfo[]> {
    return this.apiService.get<ProfileInfo[]>(`${this.resource}/search-all-users`, params).pipe(
      map(users => users.map(user => new ProfileInfo(user)))
    );
  }

  async assume(assumedUserId: string) {
    const ogUser = this.getCurrentUser();
    delete ogUser._assumedUser;

    const userId = this.getUserId();
    const newUser = await this.apiService
      .put(`${this.resource}/${userId}/assume/${assumedUserId}`)
      .pipe(map(body => new User(body)))
      .toPromise();

    this.storeImpersonatedUser(newUser, ogUser);
    this.setCurrentUser(newUser);
  }

  private storeImpersonatedUser(impersonatedUser: User, impersonatedBy: User) {
    localStorage.setItem(this.IMPERSONATED_USER, impersonatedUser ? JSON.stringify(impersonatedUser) : null);
    localStorage.setItem(this.IMPERSONATED_BY, impersonatedBy ? JSON.stringify(impersonatedBy) : null);
  }

  /**
   * Returns the oktaId of the user being impersonated
   */
  getImpersonatedUserOktaId() {
    if(this.isAssumedUser()) {
      const impersonatedUser = this.getUserFromStorage(this.IMPERSONATED_USER);
      return impersonatedUser.oktaId;
    }
    return null;
  }

  isAssumedUser() {
    const currentUser = this.currentUserSubject.value;
    const impersonatedBy = this.getUserFromStorage(this.IMPERSONATED_BY);
    const impersonatedUser = this.getUserFromStorage(this.IMPERSONATED_USER);

    // If we have set local storage for impersonated user and the impersonated by
    // then check for impersonated user
    return impersonatedUser && impersonatedBy && currentUser
      // If an impersonated user exists and it is not the one who it is impersonated by,
      // then it is impersonated.
      && impersonatedBy._id !== impersonatedUser._id

      // The current user should be the impersonated user.
      // If not, the backend already ended the impersonation
      // due to a login in a different session
      && currentUser._id === impersonatedUser._id;
  }

  // TODO no endpoint for removing assumedUser data
  async endAssume() {
    const impersonatedBy = this.getUserFromStorage(this.IMPERSONATED_BY);
    const impersonatedUser = this.getUserFromStorage(this.IMPERSONATED_USER);
    if(impersonatedBy?._id && impersonatedUser?._id){
      const newUser = await this.apiService
        .put(`${this.resource}/${impersonatedBy._id}/remove/${impersonatedUser._id}`)
        .pipe(map(body => new User(body)))
        .toPromise();
        this.storeImpersonatedUser(null, null);
        this.setCurrentUser(newUser);
    }
  }
  
   /**
   * Saves the welcome dialog boolean value to the server.
   */
    updateShowDialogValue():void {
      // Get currentUser id from localstorage.
      const currentUser = JSON.parse(localStorage.getItem(this.CURRENT_USER));
  
        // Send value to server
        this.apiService.put(`${this.resource}/current-user`,{showDialog:false, id:currentUser._id}).subscribe(()=>{console.log('got the response')}, (error) => { throw new Error(error); });
    }
}
  
