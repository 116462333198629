<div class="modal-header">
  <button class="close-button" type="button" mat-icon-button (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="modal-body">
  <div mat-dialog-content class="text-center">
    <img *ngIf="welcomeDialogImage$ | async as welDialogImg" [src]="welDialogImg"  class="img-responsive"/>
    <h6 class="mat-headline font-weight-bold" *ngIf="welcomeDialogMsg1$ | async as welDialogMsg1">
      {{welDialogMsg1}}
    </h6>
    <p class="mat-body-1" *ngIf="welcomeDialogMsg2$ | async as welDialogMsg2">
      {{welDialogMsg2}}
    </p>

  </div>
</div>
