<mat-card class="p-0 h-100 lc-card" fxLayout="column" [class.no-box]="radioButtonTemplate">
  <!-- Card Image-->
  <div mat-card-image class="w-100 m-0" *ngIf="imageTemplate">
    <template [ngTemplateOutlet]="imageTemplate"></template>
  </div>

  <mat-card-content class="m-0" [ngClass]="contentClass" fxFlex>
    <!-- Card Header-->
    <div *ngIf="headerTemplate" class="header-container border-bottom pb-2" [ngClass]="{'pt-3 px-3': size == 'lg', 'pt-2 px-2': size === 'sm'}">
      <template [ngTemplateOutlet]="headerTemplate"></template>
    </div>

    <!-- Card Content-->
    <div *ngIf="contentTemplate" [ngClass]="size == 'lg' ? 'pt-4 px-4 ' + contentClass : 'pt-2 px-2 ' + contentClass" [class.pb-4]="!actionsTemplate && size=='lg'" [class.pb-2]="!actionsTemplate && size=='sm'">
      <template [ngTemplateOutlet]="contentTemplate"></template>
    </div>

    <!-- Radio button Content-->
    <div *ngIf="radioButtonTemplate" class="header-container px-3">
      <template [ngTemplateOutlet]="radioButtonTemplate"></template>
    </div>
  </mat-card-content>

  <!-- Divider (conditional upon content and actions)-->
  <ng-container *ngIf="(headerTemplate || contentTemplate) && actionsTemplate">
    <hr class="divider-line my-0">
  </ng-container>

  <!-- Card Content-->
  <mat-card-actions class="mx-0" [ngClass]="{'p-3': size == 'lg', 'p-2': size === 'sm'}" *ngIf="actionsTemplate">
    <template [ngTemplateOutlet]="actionsTemplate"></template>
  </mat-card-actions>
</mat-card>
