// Angular Modules
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
const angularModules = [ CommonModule, FormsModule, ReactiveFormsModule, RouterModule, FlexLayoutModule ];

// Third Party Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ngxLoadingAnimationTypes, NgxLoadingComponent, NgxLoadingModule } from 'ngx-loading';
import { ClipboardModule } from 'ngx-clipboard';

const thirdPartyModules = [
  NgxFileDropModule, NgbModule, NgxMaskModule.forRoot(), ImageCropperModule,
  NgxLoadingModule.forRoot({
    animationType: ngxLoadingAnimationTypes.circleSwish,
    backdropBackgroundColour: 'rgba(0,0,0,0.1)',
    backdropBorderRadius: '4px',
    primaryColour: '#1f69ff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff'
  }),
  ClipboardModule
];

// Material Modules
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
const materialModules = [
  MatIconModule, MatSelectModule,  MatChipsModule, MatAutocompleteModule,MatDialogModule, MatDividerModule,
  MatButtonModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatTableModule
];

// LC Modules
import { PromptDialogService as CorePromptDialogService } from '@lc/core';

// Internal Services
import { PromptDialogService } from './dialogs/prompt-dialog/prompt-dialog.service';
import { NotesDialogService } from './dialogs/notes-dialog/notes-dialog.service';
import { FieldVersionDialogService } from './dialogs/versions/field-version-dailog.service';
import { PdfPreviewDialogService } from './dialogs/pdf-preview-dialog/pdf-preview-dialog.service';
import { UploadResultsDialogService } from './dialogs/upload-results-dialog/upload-results-dialog.service';
import { PhotoGalleryDialogService } from './dialogs/photo-gallery-dialog/photo-gallery-dialog.service';
import { ReasonDialogService} from './dialogs/reason-dialog/reason-dialog.service';
import { BulkAssignDialogService } from './dialogs/bulk-assign-dialog/bulk-assign-dialog.service';
import {WelcomeUiDialogService} from "./dialogs/welcome-ui-dialog/welcome-ui-dialog.service";
const services = [NotesDialogService, FieldVersionDialogService, PdfPreviewDialogService,
  UploadResultsDialogService, PhotoGalleryDialogService, ReasonDialogService, BulkAssignDialogService, WelcomeUiDialogService];

// Internal Components
import { PromptDialogComponent } from './dialogs/prompt-dialog/prompt-dialog.component';
import { NotesDialogComponent } from './dialogs/notes-dialog/notes-dialog.component';
import { FieldVersionComponent } from './dialogs/versions/field-version.component';
import { PdfPreviewDialogComponent } from './dialogs/pdf-preview-dialog/pdf-preview.dialog.component';
import { BulkAssignDialogComponent } from './dialogs/bulk-assign-dialog/bulk-assign-dialog.component';
import { PhotoGalleryDialogComponent } from './dialogs/photo-gallery-dialog/photo-gallery-dialog.component';
import { UploadResultsDialogComponent } from './dialogs/upload-results-dialog/upload-results-dialog.component';
import { ReasonDialogComponent } from './dialogs/reason-dialog/reason-dialog.component';
import { TermsAndConditionsDialogComponent } from './dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { WelcomeUiDialogComponent } from './dialogs/welcome-ui-dialog/welcome-ui-dialog.component';
const dialogComponents = [ PromptDialogComponent, NotesDialogComponent, FieldVersionComponent, BulkAssignDialogComponent, PhotoGalleryDialogComponent, UploadResultsDialogComponent, ReasonDialogComponent, TermsAndConditionsDialogComponent, PdfPreviewDialogComponent, WelcomeUiDialogComponent];
const inputComponents = [ ];
const internalComponents = [ ...dialogComponents, ...inputComponents ];

import { BrowserSupportComponent } from './browser/browser-support.component';
import { MatNativeDateModule } from '@angular/material/core';
import { InputsModule, AgentSearchModule, DialogsModule, UICoreModule } from '@lc/ui';
import { MatDividerModule } from '@angular/material/divider';

// WARNING: DO NOT IMPORT CORE MODULE HERE
@NgModule({
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...materialModules,
    InputsModule,
    UICoreModule,
    DialogsModule,
    AgentSearchModule,
    RouterModule.forChild([
      { path: 'browser-support', component: BrowserSupportComponent }
    ])
  ],
  declarations: [
    ...internalComponents,
    BrowserSupportComponent
  ],
  providers: [
    ...services
  ],
  entryComponents:[
    ...dialogComponents
  ],
  exports: [
    ...internalComponents,
    NgxLoadingComponent,
    BrowserSupportComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedComponentsUiModule {
  static forRoot(): ModuleWithProviders<SharedComponentsUiModule> {
    return {
      ngModule: SharedComponentsUiModule,
      providers: [
        // Registers the concrete implementation of the PromptDialogService for use throughout the application.
        // Overrides the core implementation which is just an empty class, similiar to an interface
        { provide: CorePromptDialogService, useClass: PromptDialogService  },
      ]
    };
  }
}
