import { Injectable } from '@angular/core';
import { createClient, ContentfulClientApi } from 'contentful';
import { AppService } from '../services/app.service';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export enum ContentKeys {
  WELCOME_MESSAGE_LINE1 = 'welcomeMessageLine1',
  WELCOME_MESSAGE_LINE2 = 'welcomeMessageLine2',
  WELCOME_MESSAGE_LINE3 = 'welcomeMessageLine3',
  APP_HEADER_IMAGE = 'appHeaderImage',
  WELCOME_DIALOG_LINE1 = 'welcomeDialogLine1',
  WELCOME_DIALOG_LINE2 = 'welcomeDialogLine2',
  WELCOME_DIALOG_IMAGE = 'welcomeDialogImage',
  CONFIRMATION_IMAGE = 'confirmationImage',
  CONFIRMATION_LINE1 = 'confirmationLine1',
  CONFIRMATION_LINE2 = 'confirmationLine2',
  CONFIRMATION_LINE3 = 'confirmationLine3',
  CONFIRMATION_LINE4 = 'confirmationLine4',
  CONFIRMATION_LINE5 = 'confirmationLine5',
  CONFIRMATION_LINE6 = 'confirmationLine6',
  GENERIC_PHONE_NUMBER = 'genericPhoneNumber'
}

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private clientApi: ContentfulClientApi;
  /** BehaviorSubjects for each of the configured/requested feature flags. */
  private readonly ContentKeys$: { [keyName: string]: BehaviorSubject<any> } = {};

  constructor() {
    const space = AppService.get('contentFulSpace');
    const accessToken = AppService.get('contentFulAccessToken');
    const contentId = AppService.get('contentId')
    if (space && accessToken) {
      this.clientApi = createClient({
        space: space,
        accessToken: accessToken,
      });
      this.getContentfulData(contentId)
    }
  }
  getContentfulData(id: string): any {
    this.clientApi.getEntry(id).then( response => {
      Object.keys(response.fields).forEach((keyFieldInContentFul) => {
        this.updateKeyValue(keyFieldInContentFul, response.fields[keyFieldInContentFul])
        });
    })
  }
  /*
   * Returns the content value from the contnetFul
   * @param flag The content key value we are returning
   */
  getContent$<TExpected = any>(content: ContentKeys | string, defaultValue: TExpected = null): Observable<TExpected> {
    return this.getContentKeys$(content, defaultValue).asObservable();
  }

  private getContentKeys$<TExpected = any>(content: ContentKeys | string, defaultValue: TExpected = null): BehaviorSubject<TExpected> {
    if (!this.ContentKeys$[content]) {
      this.ContentKeys$[content] = new BehaviorSubject(defaultValue);
    }
    return this.ContentKeys$[content];
  }
  /**
  * Stores the value for the given content key in the BehaviorSubject to be emitted
  * @param ContentKeyName The Content Key name
  * @param value The value to emit
  */
  private updateKeyValue(ContentKeyName: string, value: any) {
    const retrievedValue = this.checkForDifferentTypeOfValues(value)
    if (this.ContentKeys$[ContentKeyName] == null) {
      this.ContentKeys$[ContentKeyName] = new BehaviorSubject<any>(retrievedValue);
    } else if(!value) {
      this.ContentKeys$[ContentKeyName].next(this.ContentKeys$[ContentKeyName].value);
    }else {
      this.ContentKeys$[ContentKeyName].next(retrievedValue);
    }
  }

  private checkForDifferentTypeOfValues(objValue:any) {
    const objectType =  objValue && typeof objValue === 'object' && objValue.constructor === Object;
    if (objectType) {
      return `https:${objValue.fields.file.url}`
    } else {
      return objValue;
    }
  }
}
