// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule, MatInput } from '@angular/material/input';
import { MatSelectModule, MatSelect } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule,
  MatAutocompleteModule, MatButtonModule, MatChipsModule, MatCheckboxModule, MatDatepickerModule,
  MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule,MatProgressBarModule,
  MatProgressSpinnerModule
];
const exportedAngularModules = [FormsModule, ReactiveFormsModule, MatInputModule];

// Third Party Modules
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
const thirdPartyModules = [ ClickOutsideModule,NgxMaskModule, NgbDatepickerModule, QuillModule ];
// import { ClickOutsideModule } from 'ng-click-outside';
// Internal Components
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipsComponent } from './chips/chips.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TextboxComponent } from './textbox/textbox.component';
import { TextareaComponent } from './textarea/textarea.component';
import { CardOptionsComponent } from './card-options/card-options.component';
import { DatepickerComponent } from './date-picker/datepicker.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { RichTextComponent } from './rich-text/rich-text.component';
import { EditorControlComponent } from './rich-text/editor-control/editor-control.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TimePickerComponent , TimeValue } from './time-picker/time-picker.component';
const exportComponents = [
  CheckboxComponent, ChipsComponent, DropdownComponent, TextboxComponent, TextareaComponent, CardOptionsComponent,
  DatepickerComponent, DateRangeComponent, MultiselectComponent, PhoneNumberComponent, RichTextComponent, EditorControlComponent,
  AutocompleteComponent, TimePickerComponent
];
import { FormService } from '@lc/core';

@NgModule({
  declarations: [...exportComponents],
  exports: [...exportComponents, ...exportedAngularModules],
  imports: [
    ...angularModules,
    ...thirdPartyModules
  ],
  providers: [
    FormService
  ]
})
export class InputsModule {
  private static isConfigured: boolean;
  constructor() {
    InputsModule.configureValidators();
  }

  private static configureValidators() {
    if(InputsModule.isConfigured) { return; }

     /**
     * Fix for the MatInput required asterisk. (see https://github.com/angular/components/issues/2574#issuecomment-486656158)
     */
    const requiredImplementation = {
      get: function(): boolean {
        if (this._required) {
          return this._required;
        }

        // The required attribute is set
        // when the control return an error from validation with an empty value
        if (this.ngControl?.control?.validator) {
          const emptyValueControl = Object.assign({}, this.ngControl.control);
          (emptyValueControl as any).value = null;
          return ("required" in (this.ngControl.control.validator(emptyValueControl) || {}));
        }
        return false;
      },
      set: function(value: boolean) {
        this._required = coerceBooleanProperty(value);
      }
    };
    Object.defineProperty(MatInput.prototype, "required", requiredImplementation);
    Object.defineProperty(MatSelect.prototype, "required", requiredImplementation);
    InputsModule.isConfigured = true;
  }
}
