<ng-container *ngIf="form">
  <div class="pt-1 pb-1">
    <span class="mat-subheading-2 font-weight-bold">Search Orders</span>
  </div>
  <!-- Address and Area search container-->
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px" *ngIf="hasListing | async">
    <!-- Address Search Filter-->
    <lc-textbox fxFlex label="Search by Address" placeholder="Search by Address" [formControl]="form.search" idPrefix="search">
    </lc-textbox>

    <!-- Area Filter-->
    <lc-multiselect fxFlex label="Area" idPrefix="area" (openedChange)="onAreaClosed($event)"
      [displayOptions]="areaDisplayOptions" [options]="areas" [(ngModel)]="form.selectedAreas">
      <ng-template #optionTpl let-item="item">
        <div><span class="status-text option-title">{{item}}</span></div>
      </ng-template>
    </lc-multiselect>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
    <!-- Status Filter-->
    <lc-multiselect fxFlex idPrefix="status" label="Filter by:" (openedChange)="onStatusClosed()"
      [displayOptions]="statusDisplayOptions" [options]="form.statusOptions" [(ngModel)]="form.selectedStatuses"
      displayPath="label">
      <ng-template #optionTpl let-item="item">
        <div>
          <span class="ui-icon ui-icon-status-bullet" [ngStyle]="{'background-color': item.color}"></span>
          <span class="status-text option-title">{{item.label}}</span></div>
      </ng-template>
    </lc-multiselect>

    <!-- Date Filter-->
    <lc-multiselect #dateSelect fxFlex label="Dates" idPrefix="date" dropdownClass="date-filter" multiple="false"
      idPath="text" displayPath="display" [displayOptions]="dateDisplayOptions" [options]="form.dateOptions"
      [(ngModel)]="form.selectedDateOption" (selectionChanged)="dateSelectionChanged($event)">
      <ng-template #optionTpl let-item="item">
        <div>
          <span class="date-option-text option-title">{{item.text}}</span>
        </div>

        <lc-date-range #range *ngIf="item.text == 'Custom'" (click)="onDateRangeClicked($event, item)"
          [from]="item.startDate" (fromChanged)="item.startDate = $event; dateSelect.updateDisplay()" [to]="item.endDate"
          (toChanged)="item.endDate = $event; dateSelect.updateDisplay()"></lc-date-range>
      </ng-template>
    </lc-multiselect>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
    <div fxLayout="row" fxFlex fxLayoutGap="10px">
      <lc-dropdown *ngIf="form.userFilters === 'both'" fxFlex="0 1 125px" label="Search Users By:" [options]="form.userFilterOptions" displayPath="viewValue" valuePath="value" [(ngModel)]="form.selectedUserFilterOption"></lc-dropdown>

      <lc-agent-search *ngIf="form.selectedUserFilterOption === 'agent'" fxFlex label="Agent:" placeholder="Search by Agent name" [formControl]="form.agentSearch"
        [selectedIds]="form.selectedAgentIds" [searchBy]= "form.selectedUserFilterOption" (selectedChanged)="onAgentsSelected($event)" >
      </lc-agent-search>

      <lc-agent-search *ngIf="form.selectedUserFilterOption === 'lcc'" fxFlex label="Coordinator:" placeholder="Search by Coordinator name"
        [formControl]="form.coordinatorSearch" [selectedIds]="form.selectedCoordinatedIds"
        (selectedChanged)="onCoordinatorsSelected($event)" [searchBy]= "form.selectedUserFilterOption">
      </lc-agent-search>
    </div>

    <lc-multiselect *ngIf="form.showAssignment" fxFlex idPrefix="assignment" label="Assigned to:"
      (openedChange)="onAssignmentsClosed()" [displayOptions]="assignmentDisplayOptions"
      [options]="form.assignmentOptions" [formControl]="form.getControl('assignments')">
    </lc-multiselect>
  </div>

</ng-container>
