<div *ngIf="htmlEnabled$ | async; else formEdit"
     class="rich-text-container">
  <div class="lc-rich-text html-enabled"
       [ngClass]="{ 'text-fits': textFits, 'text-overflow': !textFits}">
    <div id="scrolling-container">
      <lc-editor-control
        [format]="format"
        [richTextEnabled]="richTextEnabled"
        [toolbarEnabled]="toolbarEnabled"
        [setBorder]="isRichTextActions"
        [minimumHeight]="minimumHeight"
        [isHeader]="isHeader"
        [isContact]="isContact"
        [formControl]="formControl"
        (change)="onTextChange($event)"
        [errorState]="hasErrors"
        [disabled]="readonly"
        [statistics]="richTextStats"
        (selectionChange)="onBlur()">

      </lc-editor-control>
    </div>
    <div *ngIf="isRichTextActions">
      <div fxLayout="row" fxLayoutAlign="right center" fxLayoutGap="5px" class="editor-actions-btn p-1">
        <button id="btn-cancel" mat-raised-button color="default" (click)="onCancel()">Cancel</button>
        <button id="btn-save" mat-raised-button color="primary" [disabled]="disableSave" (click)="onSave()">Save</button>
      </div>
    </div>
  </div>
</div>
<ng-template #formEdit>
  <mat-form-field [id]="idPrefix + '-form-field'" appearance="outline" [class.ng-valid]="errors === ''" [class.mat-form-field-readonly]="readonly" class="lc-rich-text">
  <mat-label>{{label}}</mat-label>
  <div class="lc-rich-text">
    <lc-editor-control
      [setBorder]="isRichTextActions"
      [isHeader]="isHeader"
      [isContact]="isContact"
      [formControl]="formControl"
      (change)="onTextChange($event)"
      [errorState]="hasErrors"
      [disabled]="readonly"
      [statistics]="richTextStats"
      (blur)="onBlur()"></lc-editor-control>
  </div>
  <mat-hint>{{hint}}</mat-hint>
  <mat-error *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>
  <!-- <span *ngIf="statistics && statistics.maximum"> {{statistics.maximum}}</span>-->
</mat-form-field>
</ng-template>
