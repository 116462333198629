<div fxLayout="column" fxLayoutGap="15px"  fxLayout="column" class="h-100 py-0">

  <!-- Title -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 *ngIf="!titleTemplate; else titleTemplate" mat-dialog-title class="m-0 dialog-title">{{title}}</h1>
    <button *ngIf="!removeClose" fxFlex="25px" mat-icon-button class="m-0" (click)="onCloseClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="banner-text prompt-text" *ngIf="!contentTemplate; else contentTemplate" mat-dialog-content [innerHTML]="message">
  </div>

  <!-- Actions -->
  <ng-container *ngIf="primaryAction || otherActions?.length || actionsTemplate">
    <div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px" *ngIf="!actionsTemplate; else actionsTemplate" >  
      <div *ngIf="swapPrimaryButtons$ | async; else swappedButtons">
      <button [id]="toButtonId(primaryAction.text)" *ngIf="primaryAction" type="button" mat-raised-button color="primary" (click)="onActionClicked(primaryAction)">{{primaryAction.text}}</button>
      <a class="cancel-btn" [id]="toButtonId(action.text)" *ngFor="let action of otherActions" mat-button (click)="onActionClicked(action)">{{action.text}}</a>
    </div>
  </div>

  <ng-template #swappedButtons>
    <div class="mb-4">
      <a  class="cancel-btn" [id]="toButtonId(action.text)" *ngFor="let action of otherActions" mat-button (click)="onActionClicked(action)">{{action.text}}</a>
      <button [id]="toButtonId(primaryAction.text)" *ngIf="primaryAction" type="button" mat-raised-button color="primary" (click)="onActionClicked(primaryAction)">{{primaryAction.text}}</button>
    </div>
  </ng-template>

  </ng-container>
</div>
