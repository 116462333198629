// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, MatSliderModule, MatStepperModule,
  MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatButtonModule, MatButtonToggleModule
];

// Third Party Modules
import { NgxFileDropModule } from 'ngx-file-drop';
const thirdPartyModules = [NgxFileDropModule];

// LC Modules
import { CoreModule } from '@lc/core';
import { DialogsModule } from '../dialogs/dialogs.module';
const lcModules = [ CoreModule, DialogsModule ];

import { ImageInputComponent } from './image-input/image-input.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
const exportComponents = [ImageInputComponent, FileUploadComponent, ImageCropperComponent ];

import { PhotoCropperDirective } from './directives/photo-cropper.directive';
const directives = [ PhotoCropperDirective ];

// Dialog Components
import { ImageCropperDialog } from './dialogs/image-cropper/image-cropper.dialog';
const dialogs = [ ImageCropperDialog ];

@NgModule({
  declarations: [...exportComponents, ...directives, ...dialogs ],
  exports: [...exportComponents, ...directives],
  entryComponents: [...dialogs],
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...lcModules
  ]
})
export class ImagesModule { }
