import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogAction } from '@lc/core';

@Component({
  selector: 'lc-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss']
})
export class TermsAndConditionsDialogComponent  {
  private readonly _close = new Subject<boolean>();
  public readonly close = this._close.asObservable();
  readonly accept = new DialogAction('Accept');
  readonly cancel = new DialogAction('Cancel');

  onAction(action: DialogAction) {
    this._close.next(action === this.accept);
  }

}
