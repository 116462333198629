import { AfterViewInit, Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AddKeys,
  HeaderConstants,
  LOGGEDINUSER,
  UserAgentService,
  User,
  AppInitializerService,
  AppService
} from '@lc/core';
import {Router, NavigationEnd} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { environment } from '../../../../libs/environments/environment';
import { DOCUMENT } from '@angular/common';
import { HotKeyService } from './hot-key.service';


declare let gtag: any;

@Component({
  selector: 'lc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{
  title = 'dc-coordinator-app';
  profile$: Observable<User>;
  profileEvents: any;
  OktaId: string;
  private GTAG_MAP: Map<string, string> =
  new Map([
    ['prod', 'GTM-TNWHNHJ'],
    ['uat', 'GTM-PGJ4PPS'],
    ['local','GTM-5CDP8B8']
  ]);



  constructor(private appInitializer: AppInitializerService,
              store: Store<any>,
              userAgentService: UserAgentService,
              private hotkeyService: HotKeyService,
              public router: Router,
              @Inject(DOCUMENT) private doc: any) {

    userAgentService.doBrowserCheck();

    const initKeys = {};
    initKeys[HeaderConstants.APPNAME] = this.title;
    initKeys[HeaderConstants.PRODUCTNAME] = AppService.productSuiteName;

    store.dispatch(new AddKeys(initKeys));

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    this.profile$ = store.select(LOGGEDINUSER).pipe(
      filter(user => user != null),
      map((user: User) => user),
      tap(profile => profile)
    );

    this.profile$.subscribe(user => {
      this.profileEvents = { ...user?.profile };
      this.OktaId = user?.oktaId;
    }, (error) => { throw new Error(error); });
    navEndEvent$.subscribe((e: NavigationEnd) => {
      if (AppService.get('environment') && AppService.get('environment') === 'prod') {
        window['dataLayer'] = window['dataLayer'] || [];
        const auditCreatedDate = this.getProperty(this.profileEvents,'audit.createdAt');
        const setRoundedValueAuditCreatedDate = Math.round((new Date(auditCreatedDate)).getTime()/1000 );
        const setRoundNewDateValue = Math.round((new Date()).getTime()/1000)
        const auditCreatedDateTime = auditCreatedDate ? setRoundedValueAuditCreatedDate : setRoundNewDateValue;

        if(this.profileEvents) {
        window['dataLayer'].push({
          event: 'virtualPageview', page_path: e.urlAfterRedirects,
          emailAddress: this.profileEvents ? this.profileEvents.preferredEmail : 'email',
          createdAt: auditCreatedDateTime,
          userType: 'Coordinator',
          brand: this.getProperty(this.profileEvents, 'commonProfile.company.brandCode'),
          country: 'US',
          oktaID: this.OktaId,
          personMDMID: this.getProperty(this.profileEvents, 'commonProfile.personMasterId'),
          companyMDMID: this.getProperty(this.profileEvents, 'commonProfile.company.companyMasterId'),
          companyName: this.getProperty(this.profileEvents, 'commonProfile.company.companyName')
        });
        }

        this.setGTagManager();
      }
      this.appInitializer.refreshNotifications(e);
    }, (error) => { throw new Error(error); });
  }
  getProperty(object, propertyPath) {
    try {
      // tslint:disable-next-line:no-eval
      const value = eval('object.' + propertyPath);
      return value ? value : '';
    } catch (e) {
      return '';
    }
  }
  private setGTagManager() {
    const s = this.doc.createElement('script');
    const gTag = this.GTAG_MAP.get(AppService.get('environment'));
    s.type = 'text/javascript';
    s.innerHTML = `(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gTag}')`;
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }

  ngAfterViewInit() {
    if(AppService.isCoordinatorApp) {
      this.hotkeyService.initialaizeHotKeyEvent();
    }
  }
}
