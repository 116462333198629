<ng-template #photoUpload>
  <label *ngIf="label" class="form-label" [for]="inputId || 'uploadPhotos'">{{label}}</label>
  <ngx-file-drop (onFileDrop)="handleDroppedFiles($event)" customstyle="mb-4 lc-drop-zone" id="lc-file-upload">
    <ng-template ngx-file-drop-content-tmp>
    <div *ngIf="!processing" class="dz-margin align-center">
      <div>
        <div>
          <span class="text-faded">{{instructions}}</span>
        </div>
        <div class="mt-2">
          <img [src]="imageUrl || '/assets/images/upload.svg'" alt="upload images" height="63px" [class.round]="photoStyle === 'round'">
        </div>
        <div class="mt-2 mb-5">
          <input #fileInput type="file" multiple class="d-none lc-file-upload-input" [accept]="allowExt" [id]="inputId || 'uploadPhotos'" (click)="fileInput.value = null" (change)="handleSelectedFiles($event)" />
          <button type="button" mat-raised-button class="lc-file-upload-btn" [id]="btnId || 'chooseFileButton'" (click)="chooseFiles();">Upload</button>
        </div>
    </div>
    </div>
    <div *ngIf="processing">
      <div class="col">
        <p class="text-faded">Please wait while uploading the file(s)...</p>
      </div>
    </div>
    </ng-template>
  </ngx-file-drop>
</ng-template>
<ng-container *ngTemplateOutlet="uploadTemplate ? uploadTemplate: photoUpload"></ng-container>
