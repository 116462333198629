<h6 *ngIf="label" class="form-label form-margin-top" [id]="idPrefix + '-label'"
  [ngClass]="{'card-label text-wrap': label.includes('additional option(s)')}"
  [for]="idPrefix + '-input'">{{label}}</h6>

<!--Card container-->
<div class="scroll-container clean-scroll-bar">
  <div [id]="idPrefix + '-card-container'" *ngIf="options$ | async as options" fxLayout="row wrap"
    class="card-container">

    <!--Cards-->
    <ng-container *ngIf="options">
      <div fxFlex="0 0 530px" class="ml-0" *ngFor="let option of options; let i = index"
        (mouseenter)="onHover(option)" (mouseleave)="onHover()">
        <!-- Each card is 125 x 125 with a 5 px padding ==> 115 x 115 inside-->
        <a [id]="parseId('card-option-' + (option.id || option.text || i))"
          class="mat-elevation-z1 mat-card card-option clickable mt-2" fxLayout="row" [class.disabled]="!option.isEnabled"
          [ngClass]="{'option-selected': option.isSelected}" (click)="selectOption(options, option)">

          <!--Card Image 95 of the 115 px-->
          <div class="image-container ml-2" fxLayout="column" fxLayoutAlign="start center">

            <!-- Card Image -->
            <template class="logo-img" *ngIf="imageTemplate; else defaultImage" [ngTemplateOutlet]="imageTemplate"
              [ngTemplateOutletContext]="{option: option}" fxLayoutAlign="start"></template>
            <ng-template #defaultImage>
              <div *ngIf="label === 'Agent Type'">
                <button *ngIf="!option.imageSource.includes('/')" mat-mini-fab class="md small-icon" color="primary" [disabled]="!option.isSelected || option.isSelected"
                   [ngClass]="{'bg-primary': option.isSelected}">
                  <span *ngIf="option.isSelected" class="material-icons" [id]="option.imageSource">{{option.imageSource}}</span>
                  <span *ngIf="!option.isSelected" class="material-icons" [id]="option.imageSource">{{option.imageSource}}</span>
                </button>
              </div>
              <div *ngIf="label !== 'Agent Type' || option.imageSource.includes('/')">
                <img *ngIf="!option.isSelected && option.imageSource" class="logo-img"
                [src]="option.imageSource" />
              <img *ngIf="option.isSelected && option.selectedImageSource" class="logo-img"
                [src]="option.selectedImageSource" />
              </div>
            </ng-template>

            <!-- Overlay Icon-->
            <div *ngIf="maxSelect === 1; else multiSelect">
              <span [id]=idPrefix *ngIf="!option.model.isBundled && option.isSelected" class="far top-right"><span
                  class="material-icons link-color">radio_button_checked</span></span>
              <span [id]=idPrefix *ngIf="!option.model.isBundled && !option.isSelected" class="far top-right"><span
                  class="material-icons">radio_button_unchecked</span></span>
            </div>

            <ng-template #multiSelect>
              <span [id]=idPrefix *ngIf="!option.model.isBundled && option.isSelected" class="far top-right"><span
                  class="material-icons link-color">check_box</span></span>
              <span [id]=idPrefix *ngIf="!option.model.isBundled && !option.isSelected" class="far top-right"><span
                  class="material-icons">check_box_outline_blank</span></span>
            </ng-template>
          </div>
          <!--Display Label: Remaining 20 of the 115px-->

          <span fxLayout="column" fxLayoutAlign="center" class="description-text ml-2 mb-2">
            <template *ngIf="footerTemplate; else defaultFooter" [ngTemplateOutlet]="footerTemplate"
              [ngTemplateOutletContext]="{option: option}"></template>
            <ng-template #defaultFooter>
              <span class="card-label text-wrap"
                [ngClass]="{'link-color': option.isSelected, '': !option.isSelected}">{{ option.text
                }}</span>
            </ng-template>
          </span>
        </a>
      </div>
    </ng-container>
  </div>
</div>
<!-- <input [id]="idPrefix + '-input'" class="form-control" [placeholder]="placeholder" type="text" [mask]="inputMask?.mask" [patterns]="inputMask?.pattern" [formControl]="formControl" > -->
<small [id]="idPrefix + '-error'" [innerHTML]="errors || '&nbsp;'" class="invalid-feedback d-block"></small>