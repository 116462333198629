<mat-form-field appearance="outline" [id]="idPrefix + '-input'" [class.has-selected]="selectedValues?.length">

  <!-- Prefix -->
  <div matPrefix>
    <template *ngIf="prefixTemplate; else defaultPrefix" [ngTemplateOutlet]="prefixTemplate" ></template>
    <ng-template #defaultPrefix>
      <span *ngIf="prefix">{{prefix}}</span>
    </ng-template>
  </div>

  <mat-label>{{label}}</mat-label>
  <mat-chip-list #chipList aria-label="Chips Selection" [errorStateMatcher]="matcher">
    <mat-chip
      *ngFor="let option of selectedValues"
      [selectable]="false"
      [removable]="true"
      (removed)="onItemRemoved(option)">
      <ng-container *ngIf="tagTemplate == null; else tagTmp">
        {{displayPath ? (option[displayPath] || option) : option}}
      </ng-container>

      <ng-template #tagTmp>
        <template [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{item: option}"></template>
      </ng-template>

      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      [disabled]="!canAdd()"
      #chipInput
      required
      (input)="onUserInput($event)"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onInputAdd($event)">
  </mat-chip-list>

  <mat-hint>{{hint}}</mat-hint>
  <mat-error [id]="idPrefix + '-error'" *ngIf="errors" [innerHTML]="errors"></mat-error>
  <!-- <mat-error [id]="idPrefix + '-error'" *ngIf="inputFormControl && inputFormControl.invalid && errors" [innerHTML]="errors"></mat-error> -->

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)" (change)="selectedChanged.emit($event)" class="z-2000">
    <ng-container *ngIf="options$ | async as options">
      <!-- No Results Option: Displays when 0 results-->
      <mat-option *ngIf="options.length === 0 && (noResultsDisplay || noResultsTemplate)" class="no-results" disabled>
        <ng-container *ngIf="noResultsTemplate; else defaultNoResultsTemplate">
          <template [ngTemplateOutlet]="noResultsTemplate"></template>
        </ng-container>
        <ng-template #defaultNoResultsTemplate>
          {{noResultsDisplay}}
        </ng-template>
      </mat-option>

      <!-- Results Options -->
      <mat-option *ngFor="let option of options" [value]="option">
        <ng-container *ngIf="optionTemplate == null; else optTemplate">
          {{displayPath ? option[displayPath] : option}}
        </ng-container>
        <ng-template #optTemplate>
          <template [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{item: option}"></template>
        </ng-template>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
