import {Component, Inject, OnInit} from '@angular/core';
import {ContentfulService, ContentKeys} from "@lc/core";
import {Observable, Subject} from "rxjs";


@Component({
  selector: 'lc-welcome-dialog',
  templateUrl: './welcome-ui-dialog.component.html',
  styleUrls: ['./welcome-ui-dialog.component.scss']
})
export class WelcomeUiDialogComponent implements OnInit{

  private readonly _close = new Subject<any>();
  public readonly close = this._close.asObservable();

  welcomeDialogMsg1$: Observable<any>;
  welcomeDialogMsg2$: Observable<any>;
  welcomeDialogImage$: Observable<any>;

  constructor(public contentfulService: ContentfulService) { }


  ngOnInit(): void {
    this.welcomeDialogMsg1$ = this.contentfulService.getContent$(ContentKeys.WELCOME_DIALOG_LINE1, 'THE LISTING CONCIERGE YOU KNOW AND LOVE JUST GOT A MAKEOVER.');
    this.welcomeDialogMsg2$ = this.contentfulService.getContent$(ContentKeys.WELCOME_DIALOG_LINE2, ' Enjoy all the same features with more suave');
    this.welcomeDialogImage$ = this.contentfulService.getContent$(ContentKeys.WELCOME_DIALOG_IMAGE, '../../../assets/images/welcome.jpg');
    
  }

  onCancel(): void {
    this._close.next(true);
  }

}
