<lc-base-dialog id="notes-dialog" title="Terms and conditions at checkout:" [primaryAction]="accept" [otherActions]="[cancel]" (actionClicked)="onAction($event)">

  <ng-template #content>
    <p class="mat-body-1">
      If you did not enter all of your information (including listing information, content and marketing copy),
      please return to the Listing Details section and complete the information. You may continue to make
      changes until the Listing Concierge Coordinator has accepted the order.
    </p>
    <p class="mat-body-1">
      Once the Listing Concierge Coordinator has accepted the order, certain features of the Listing Concierge
      platform will be locked until he/she submits your proofs.
    </p>
    <p class="mat-body-1">
      When you receive your proof(s), it is your responsibility to ensure that you thoroughly review the content, photographs, layout, spelling, color, print marks, etc. Coldwell Banker, as well as the Listing Concierge Coordinator, office staff and Branch Manager are not responsible for issues with your orders that have been pre-approved by you or your assistant, designer, team member, etc.
    </p>
    <p class="mat-body-1">
      Office staff and employees of Coldwell Banker are not permitted to approve your orders on your behalf.
    </p>
    <p class="mat-body-1">
      We do not recommend that you approve your proof on your phone or smaller mobile device (i.e. tablet or iPad).
      It is highly recommended that you login to a desktop to review your proof so you may check the entire
      piece for content and design approval.
    </p>
    <p class="mat-body-1">
      Once you have approved your order, the item will immediately go to print. There is no canceling orders
      after you have approved your proof. Please be mindful of this as you submit approvals.
    </p>
  </ng-template>
</lc-base-dialog>
