<div style="position: relative" [id]="idPrefix + '-container'">
  <mat-form-field [id]="idPrefix + '-input'" appearance="outline" [class.selected]="isSelected">
    <mat-label [id]="idPrefix + '-label'" >{{label}}</mat-label>
    <mat-select *ngIf="options$ | async as selectOptions" [attr.aria-owns]="getOptionAriaId()" [formControl]="formControl" [multiple]="multiple" [panelClass]="dropdownClass"
                disableOptionCentering="true" [placeholder]="display" (selectionChange)="onSelectionChanged(selectOptions)"

                (openedChange)="onOpenedChange($event)">
      <mat-select-trigger>
        {{ display }}
      </mat-select-trigger>

      <mat-option disabled *ngIf="displayOptions" id="disabled-option" class="header-option">
        <!-- This is a hack to get the menu option list to display a header-->
        {{displayOptions.display}}
      </mat-option>

      <mat-option *ngFor="let option of selectOptions" [value]="valuePath ? option[valuePath] : option" [id]="getOptionId(option)">
        <span *ngIf="optionTemplate == null; else optTemplate" class="option-title">
          {{ displayPath ? option[displayPath] : option }}
        </span>
        <ng-template #optTemplate>
          <template [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{item: option}"></template>
        </ng-template>
      </mat-option>
    </mat-select>

    <mat-icon class="clear-button" matSuffix (click)="clear($event)" *ngIf="isSelected">
      close
    </mat-icon>

    <mat-hint *ngIf="hint" [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
    <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>
  </mat-form-field>
</div>
