import { Injectable } from '@angular/core';
import { WelcomeUiDialogComponent } from './welcome-ui-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@lc/core';
import { error } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class WelcomeUiDialogService {
  dialogValue: Boolean;
  private readonly CURRENT_USER = 'currentUser';

  constructor(private modal: MatDialog, public userService: UserService) {
    this.dialogValue = JSON.parse(localStorage.getItem(this.CURRENT_USER)).showDialog;
  }

  /**
   * Opens the welcome UI dialog component with the required parameters
   * @param welcomeDialogImage The image which we get from contentful service
   * @param welcomeDialogLine1 The main text from contentful service
   * @param welcomeDialogLine2 The second text from contentful service
   */
  openDialog() {
    const dialogRef = this.modal.open(WelcomeUiDialogComponent, {width: '750px', maxWidth: '90vw', maxHeight: '90vh' });

    const component: WelcomeUiDialogComponent = dialogRef.componentInstance;

    component.close.subscribe(closed => dialogRef.close(closed), err => { throw new Error(err); });
    dialogRef.afterClosed().subscribe(result => {
      if(this.dialogValue){
        this.userService.updateShowDialogValue();
      }
    },
    err => {
       throw new Error(err);
    }
    );
  }
}
